import React from "react";
import Founders from "../components/Founders";
import Header from "../components/Header";

const Founders_description = () => {
  return (
    <div className="founders_page">
      <Header />
      <h2>Notre équipe</h2>
      <Founders />
    </div>
  );
};

export default Founders_description;
