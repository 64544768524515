import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import FoundersDescription from "./pages/FoundersDescription";
import PreRegistration from "./pages/PreRegistration";
import Error404 from "./pages/Error404";
import ConfirmedRegistration from "./pages/ConfirmedRegistration";
import Help from "./pages/Help";
import CGU from "./pages/CGU";
import PDC from "./pages/PDC";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/founders" element={<FoundersDescription />} />
        <Route exact path="/registration" element={<PreRegistration />} />
        <Route exact path="/confirmation" element={<ConfirmedRegistration />} />
        <Route exact path="/help" element={<Help />} />
        <Route exact path="/cgu" element={<CGU />} />
        <Route exact path="/pdc" element={<PDC />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/404" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
