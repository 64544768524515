import React from "react";
import Header from "../components/Header";

const ConfirmedRegistration = () => {
  return (
    <div>
      <Header />
      <div className="confirmedRegistrationPage">
        <h2>Merci pour votre inscription !</h2>
        <p>
          Restez vigilants ! Vous recevrez un mail contenant plus d'informations
          dès que l'application sera en ligne
          <br />
          <br />A bientôt !
        </p>
      </div>
    </div>
  );
};

export default ConfirmedRegistration;
