import React from "react";
import Header from "../components/Header";
import Video from "../components/Video";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="home">
      <Header />
      <div className="home-container">
        <div className="home-left-section">
          <h2>
            Découvrez une nouvelle manière de faire des rencontres.
            <br />
            Il ne suffit que d'un instant.
            <br />
            Rejoignez-nous dès maintenant
          </h2>
          <div className="download-links">
            <div className="appstore-badge"></div>
            <div className="playstore-badge"></div>
          </div>
        </div>
        <div className="home-right-section"></div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
