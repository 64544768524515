import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [showBurger, setShowBurger] = useState(false);

  const handleShowBurger = () => {
    setShowBurger(!showBurger);
  };
  return (
    <div className="header">
      <div className="logo"></div>
      <div className={`navigation ${showBurger ? "show_burger" : ""}`}>
        <ul className={`navbar_links ${showBurger ? "show_burger" : ""}`}>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to="/"
          >
            <li>Accueil</li>
          </NavLink>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to="/founders"
          >
            <li>A propos</li>
          </NavLink>
          <NavLink
            className={(nav) => (nav.isActive ? "nav-active" : "")}
            to="/registration"
          >
            <li>
              <span>Télécharger</span>
            </li>
          </NavLink>
        </ul>
        <button className="navbar_burger" onClick={handleShowBurger}>
          <span className="burger_bar"></span>
        </button>
      </div>
    </div>
  );
};

export default Header;
