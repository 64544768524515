import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <ul className="footerList">
        <NavLink
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          to="/help"
        >
          <li>Besoin d'aide ?</li>
        </NavLink>
        <NavLink
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          to="/cgu"
        >
          <li>Conditions générales d'utilisation</li>
        </NavLink>
        <NavLink
          className={(nav) => (nav.isActive ? "nav-active" : "")}
          to="/pdc"
        >
          <li>Politique de confidentialité</li>
        </NavLink>
      </ul>
    </div>
  );
};

export default Footer;
