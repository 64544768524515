import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const PDC = () => {
  return (
    <div className="PDC">
      <Header />
      <h2>Politique de confidentialité</h2>
      <p>
        <span>Article 1 : Qui sommes-nous ? </span>
        <br />
        <br />
        Instantt 20 avenue du parc d’Espagne 33600 Pessac France
        <br />
        <br />
        <br />
        <span>Article 2 : Portée de cette Politique de confidentialité</span>
        <br />
        <br />
        La présente Politique de confidentialité s’applique aux sites Web, aux
        applications, aux événements et aux autres services exploités par la
        marque instantt. Par souci de simplicité, tous ces éléments seront
        désignés sous le nom de nos « services » dans cette Politique de
        confidentialité.
        <br />
        <br /> <br />
        <span>Article 3 : Informations que nous recueillons</span> <br />
        <br />
        Bien entendu, pour faciliter les rencontres, il est essentiel d'obtenir
        certaines informations te concernant, telles que les données
        fondamentales de ton profil et tes préférences en matière de rencontres.
        Nous recueillons également des informations sur la manière dont tu
        utilises nos services, notamment à travers des journaux d'accès, et des
        données provenant de sources tierces, comme lorsque tu accèdes à nos
        services via ton compte sur un réseau social ou lorsque tu importes des
        informations d'un réseau social pour enrichir ton profil. Pour de plus
        amples informations, nous fournissons des détails supplémentaires
        ci-dessous. Les informations que tu nous donnes Lorsque tu utilises nos
        services, tu choisis de nous communiquer certaines informations, par
        exemple :<br /> • Lors de la création de ton compte, tu nous fournis au
        minimum tes identifiants de connexion, ainsi que des informations de
        base essentielles au fonctionnement du service, telles que ton genre, ta
        date de naissance, et tes préférences.
      </p>
      <Footer />
    </div>
  );
};

export default PDC;
