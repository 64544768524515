import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../components/Header";

const Error_404 = () => {
  return (
    <div>
      <Header />
      <div className="errorPage">
        <h3>404</h3>
        <p>Oups ! Cette page n'existe pas</p>
        <NavLink to="/">
          <button className="homepage_button">
            Retour à la page d'accueil
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default Error_404;
