import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Help = () => {
  return (
    <div>
      <Header />
      <div className="help">
        <a href="mailto: instanttsupport@gmail.com">
          Besoin d'aide ? N'hésitez pas à nous poser toutes vos questions !
          <br />
          <br />
          Cliquez-ici ou contactez nous à Instanttsupport@gmail.com
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Help;
