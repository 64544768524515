import React from "react";

const Founders = () => {
  return (
    <div className="first_group">
      <div className="first_portrait">
        <div className="founders_photo_1"></div>
        <h3>CEO - CAMACHO Julien</h3>
        <h4>Étudiant à la KEDGE Business School</h4>
        <p>
          Julien gère la stratégie et le développement de l’entreprise tout en
          survolant tous les autres domaines de l'entreprise (marketing,
          communication, IT, développement commercial). Passionné par la gestion
          de projet innovant et l’apport de nouveauté à la société. Persuadé
          qu’il manque un certain dynamisme et d’humanité sur le marché actuel
          des sites de rencontres, il décide avec son cofondateur Yanis MEZOUGHI
          de se lancer dans l’aventure entrepreneuriale en lançant Instantt.
          <br /> Une citation inspirante : Le meilleur moyen de prévoir le futur
          c’est de le créer.
        </p>
      </div>

      <div className="second_portrait">
        <div className="founders_photo_2"></div>
        <h3>CFO - MEZOUGHI Yanis</h3>
        <h4>Étudiant à la KEDGE Business School</h4>
        <p>
          Issu d’un parcours plus financier, Yanis est chargé de la partie
          financière de l’entreprise. Il joue également un rôle majeur dans le
          développement commercial de l’entreprise et la stratégie de Instantt.
          Fin connaisseur du marché des sites de rencontres, il est bien décidé
          à révolutionner un marché qui est aujourd’hui redondant et trop peu
          créatif.
          <br /> Une citation inspirante : “Seulement ceux qui prendront le
          risque d’aller trop loin découvriront jusqu’où on peut aller”
        </p>
      </div>
      <div className="third_portrait">
        <div className="founders_photo_3"></div>
        <h3>CTO - REIGNER Benjamin</h3>
        <h4>EPITECH Student</h4>
        <p>
          Benjamin est responsable du développement technique de l’application.
          Spécialisé sur la partie back, il assure la traduction des visions des
          fondateurs en réalité technique. Il dirige l’ensemble de la partie
          technique liée à l’app de l’entreprise. Toujours prêt à relever des
          défis quand il s’agit de projet innovant, il fait partie des
          fondateurs de l’entreprise.
        </p>
      </div>
      <div className="fourth_portrait">
        <div className="founders_photo_4"></div>
        <h3>Développeur - DA SILVA Alex</h3>
        <p>
          Alex fait partie de l’équipe IT de l’entreprise, il est notre
          responsable Front. Son rôle est d’assurer que l’utilisateur vive la
          meilleure expérience possible sur l’app en rendant l’application
          design, simple et ludique. Intéressé à l’idée de créer une application
          qui sort de l'ordinaire, il a rejoint Instantt dès sa création dans
          l’optique d’apporter ses compétences au service d’un produit novateur.
        </p>
      </div>
    </div>
  );
};

export default Founders;
