import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CGU = () => {
  return (
    <div className="CGU">
      <Header />
      <h2>Conditions générales d'utilisations</h2>
      <p>
        En vigueur au 13/11/2023
        <br />
        <br />
        <br /> Les présentes conditions générales d'utilisation (dites « CGU »)
        ont pour objet l'encadrement juridique des modalités de mise à
        disposition du site et des services par Instantt et de définir les
        conditions d’accès et d’utilisation des services par « l'Utilisateur ».
        Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
        Toute inscription ou utilisation du site implique l'acceptation sans
        aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors
        de l'inscription sur le site via le Formulaire d’inscription, chaque
        utilisateur accepte expressément les présentes CGU en cochant la case
        précédant le texte suivant : « Je reconnais avoir lu et compris les CGU
        et je les accepte ». En cas de non-acceptation des CGU stipulées dans le
        présent contrat, l'Utilisateur se doit de renoncer à l'accès des
        services proposés par le site. Instantt se réserve le droit de modifier
        unilatéralement et à tout moment le contenu des présentes CGU.
        <br />
        <br />
        <br /> <span>Article 1 :</span> Les mentions légales L'édition du site
        est assurée par la Société SAS Instantt au capital de 3530 euros,
        immatriculée au RCS de Bordeaux sous le numéro 951 009 612, dont le
        siège social est situé au 20 Avenue du Parc D’Espagne 33600 Pessac
        Numéro de téléphone 0658104309 Adresse e-mail :
        julien.camacho.pro@gmail.com. Le Directeur de la publication est :
        Camacho Julien L'hébergeur du site est la société OVH, dont le siège
        social est situé au 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1,
        avec le numéro de téléphone : 1007.
        <br />
        <br />
        <br /> <span>Article 2 :</span> Accès au site Le site permet à
        l'Utilisateur un accès gratuit aux services suivants : Le site internet
        propose les services suivants : Site de rencontre ; mise en relation de
        personnes; réseau social; Le site est accessible gratuitement en tout
        lieu à tout Utilisateur ayant un accès à Internet. Tous les frais
        supportés par l'Utilisateur pour accéder au service (matériel
        informatique, logiciels, connexion Internet, etc.) sont à sa charge.
        L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela,
        il doit s’inscrire en remplissant le formulaire. En acceptant de
        s’inscrire aux services réservés, l’Utilisateur membre s’engage à
        fournir des informations sincères et exactes concernant son état civil
        et ses coordonnées, notamment son adresse email. Pour accéder aux
        services, l’Utilisateur doit ensuite s'identifier à l'aide de son
        identifiant et de son mot de passe qui lui seront communiqués après son
        inscription. Tout Utilisateur membre régulièrement inscrit pourra
        également solliciter sa désinscription en se rendant à la page dédiée
        sur son espace personnel. Celle-ci sera effective dans un délai
        raisonnable. Tout événement dû à un cas de force majeure ayant pour
        conséquence un dysfonctionnement du site ou serveur et sous réserve de
        toute interruption ou modification en cas de maintenance, n'engage pas
        la responsabilité d’Instantt. Dans ces cas, l’Utilisateur accepte ainsi
        ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de
        service, même sans préavis. L'Utilisateur a la possibilité de contacter
        le site par messagerie électronique à l’adresse email de l’éditeur
        communiqué à l’Article 1.
        <br />
        <br />
        <br /> <span>Article 3 :</span> Collecte des données Le site assure à
        l'Utilisateur une collecte et un traitement d'informations personnelles
        dans le respect de la vie privée conformément à la loi n°78-17 du 6
        janvier 1978 relative à l'informatique, aux fichiers et aux libertés. En
        vertu de la loi Informatique et Libertés, en date du 6 janvier 1978,
        l'Utilisateur dispose d'un droit d'accès, de rectification, de
        suppression et d'opposition de ses données personnelles. L'Utilisateur
        exerce ce droit : · par mail à l'adresse email : app.instantt@gmail.com.
        <br />
        <br />
        <br /> <span>Article 4 :</span> Propriété intellectuelle Les marques,
        logos, signes ainsi que tous les contenus du site (textes, images, son…)
        font l'objet d'une protection par le Code de la propriété intellectuelle
        et plus particulièrement par le droit d'auteur. L'Utilisateur doit
        solliciter l'autorisation préalable du site pour toute reproduction,
        publication, copie des différents contenus. Il s'engage à une
        utilisation des contenus du site dans un cadre strictement privé, toute
        utilisation à des fins commerciales et publicitaires est strictement
        interdite. Toute représentation totale ou partielle de ce site par
        quelque procédé que ce soit, sans l’autorisation expresse de
        l’exploitant du site Internet constituerait une contrefaçon sanctionnée
        par l’article L 335-2 et suivants du Code de la propriété
        intellectuelle. Il est rappelé conformément à l’article L122-5 du Code
        de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou
        publie le contenu protégé doit citer l’auteur et sa source.
        <br />
        <br />
        <br /> <span>Article 5 :</span> Responsabilité Les sources des
        informations diffusées sur le site sont réputées fiables mais le site ne
        garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les
        informations communiquées sont présentées à titre indicatif et général
        sans valeur contractuelle. Malgré des mises à jour régulières, le site
        ne peut être tenu responsable de la modification des dispositions
        administratives et juridiques survenant après la publication. De même,
        le site ne peut être tenue responsable de l’utilisation et de
        l’interprétation de l’information contenue dans ce site. L'Utilisateur
        s'assure de garder son mot de passe secret. Toute divulgation du mot de
        passe, quelle que soit sa forme, est interdite. Il assume les risques
        liés à l'utilisation de son identifiant et mot de passe. Le site décline
        toute responsabilité. Le site ne peut être tenu pour responsable
        d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel
        informatique de l’Internaute, suite à une utilisation, à l’accès, ou au
        téléchargement provenant de ce site. La responsabilité du site ne peut
        être engagée en cas de force majeure ou du fait imprévisible et
        insurmontable d'un tiers.
        <br />
        <br />
        <br /> <span>Article 6 :</span> Liens hypertextes Des liens hypertextes
        peuvent être présents sur le site. L’Utilisateur est informé qu’en
        cliquant sur ces liens, il sortira du site. Ce dernier n’a pas de
        contrôle sur les pages web sur lesquelles aboutissent ces liens et ne
        saurait, en aucun cas, être responsable de leur contenu.
        <br />
        <br />
        <br /> <span>Article 7 :</span> Cookies L’Utilisateur est informé que
        lors de ses visites sur le site, un cookie peut s’installer
        automatiquement sur son logiciel de navigation. Les cookies sont de
        petits fichiers stockés temporairement sur le disque dur de l’ordinateur
        de l’Utilisateur par votre navigateur et qui sont nécessaires à
        l’utilisation du site. Les cookies ne contiennent pas d’information
        personnelle et ne peuvent pas être utilisés pour identifier quelqu’un.
        Un cookie contient un identifiant unique, généré aléatoirement et donc
        anonyme. Certains cookies expirent à la fin de la visite de
        l’Utilisateur, d’autres restent. L’information contenue dans les cookies
        est utilisée pour améliorer le site. En naviguant sur le site,
        L’Utilisateur les accepte. L’Utilisateur pourra désactiver ces cookies
        par l’intermédiaire des paramètres figurant au sein de son logiciel de
        navigation.
        <br />
        <br />
        <br /> <span>Article 8 :</span> Droit applicable et juridiction
        compétente La législation française s'applique au présent contrat. En
        cas d'absence de résolution amiable d'un litige né entre les parties,
        les tribunaux français seront seuls compétents pour en connaître. Pour
        toute question relative à l’application des présentes CGU, vous pouvez
        joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
        <br />
        <br />
        <br /> <span>Article 9 :</span> Règles de la communauté. En utilisant le
        Service, vous acceptez de vous abstenir : • d'utiliser le Service à
        toute fin illégale ou interdite par le présent Accord. • d'utiliser le
        Service à des fins malveillantes ou malfaisantes • d'utiliser le Service
        afin de porter atteinte à Instantt • d'envoyer du courrier indésirable,
        de demander de l'argent aux membres ou de les escroquer. • de vous faire
        passer pour toute une autre personne ou entité ou de publier toutes
        images d'une autre personne sans sa permission. • de persécuter, de
        traquer, d'intimider, d'attaquer, de harceler, de maltraiter ou de
        diffamer toute personne. • de publier tout Contenu qui viole ou enfreint
        les droits de quiconque, y compris les droits de publicité, au respect
        de la vie privée, d'auteur, de marque commerciale ou tout autre droit de
        propriété intellectuelle ou contractuel. • de publier tout Contenu
        constituant un discours haineux, menaçant, sexuellement explicite ou
        pornographique. • de publier tout Contenu incitant à la violence ou
        contenant de la nudité, de la violence graphique ou gratuite. • de
        publier du contenu qui comprend des propos racistes, intolérants,
        haineux ou qui encourage les violences physiques contre un groupe ou une
        personne en particulier. • de demander des mots de passe à quelque fin
        que ce soit, ou des informations permettant une identification
        personnelle à des fins commerciales ou illégales auprès d'autres membres
        ou de diffuser les informations personnelles d'une autre personne sans
        sa permission. • d'utiliser le compte d'un autre membre, partager un
        compte avec un autre membre ou posséder plus d'un compte. • de créer un
        autre compte si nous avons déjà résilié votre compte, à moins que vous
        n'ayez notre permission. Instantt se réserve le droit d'enquêter et/ou
        de clôturer votre compte sans rembourser vos achats, si vous avez
        enfreint le présent Accord, fait une mauvaise utilisation du Service ou
        si vous avez eu un comportement que Instantt considère inapproprié ou
        illégal, y compris en cas d’actions ou de communications survenant en
        dehors du Service.
        <br />
        <br />
        <br /> <span>Article 10 :</span> Admissibilité. Vous n'êtes pas autorisé
        à créer un compte, ni à accéder ou utiliser le Service, ou les systèmes
        sur lequel il réside, si toutes les informations suivantes ne sont pas
        vraies : • vous êtes âgés au moins de 18 ans. • vous pouvez conclure un
        contrat créant des obligations avec Instantt, • vous n'êtes pas une
        personne à qui il est interdit d'utiliser le Service en vertu des lois
        des États-Unis ou de toute autre juridiction applicable (par exemple,
        que vous n'apparaissez pas sur la liste des Nationaux spécifiquement
        désignés établie par le Département du Trésor américain ni ne faites
        l'objet d'aucune autre interdiction similaire), • vous respecterez le
        présent Accord et l'ensemble des lois, règles et règlements locaux,
        étatiques, nationaux et internationaux applicables, et • vous n'avez
        jamais été condamné pour un crime ou un délit passible de poursuites (ou
        un crime de gravité similaire), un crime à caractère sexuel ou
        impliquant de la violence, et n'êtes pas tenu de vous déclarer en tant
        que délinquant sexuel auprès de tout registre des délinquants sexuels au
        niveau étatique, fédéral ou local.
        <br />
        <br />
        <br /> <span>Article 11 :</span> Avis de non-responsabilité. INSTANTT
        FOURNIT LE SERVICE « TEL QUEL » ET « SELON LA DISPONIBILITÉ » ET, DANS
        LA MESURE AUTORISÉE PAR LA LOI APPLICABLE, N'ACCORDE AUCUNE GARANTIE DE
        QUELQUE NATURE QUE CE SOIT, QU'ELLE SOIT EXPRESSE, IMPLICITE,
        OBLIGATOIRE OU AUTRE CONCERNANT LE SERVICE (Y COMPRIS TOUT LE CONTENU DE
        CELUI-CI), INCLUANT SANS S'Y LIMITER TOUTE GARANTIE IMPLICITE DE QUALITÉ
        SATISFAISANTE, QUALITÉ MARCHANDE, ADAPTATION À UNE FIN PARTICULIÈRE OU
        ABSENCE DE CONTREFAÇON. INSTANTT NE DÉCLARE NI NE GARANTIT QUE (A) LE
        SERVICE SERA SANS INTERRUPTION, SÛR OU LIBRE D'ERREUR, (B) TOUS LES
        DÉFAUTS OU ERREURS DU SERVICE SERONT CORRIGÉS, OU (C) QUE TOUT CONTENU
        OU TOUTE INFORMATION QUE VOUS OBTENEZ SUR OU À TRAVERS LES SERVICES SERA
        EXACT. INSTANTT N'ASSUME AUCUNE RESPONSABILITÉ POUR TOUT CONTENU QUE
        VOUS, UN AUTRE MEMBRE OU UN TIERS PUBLIE, ENVOIE OU REÇOIT VIA LE
        SERVICE. L'ACCÈS À TOUT MATÉRIEL TÉLÉCHARGÉ OU OBTENU À TRAVERS
        L'UTILISATION DU SERVICE EST À VOTRE ENTIÈRE DISCRÉTION ET À VOS PROPRES
        RISQUES.
      </p>
      <Footer />
    </div>
  );
};

export default CGU;
