import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeviceDetector from "device-detector-js";
import {Register} from "../BackendSDK/User/Register";
import {AddProfilePicture} from "../BackendSDK/ProfilePictures/Add";
import {AddVerificationPicture} from "../BackendSDK/VerificationPictures/Add";
import {ErrorMessage, FastField, Field, Form, Formik} from "formik";
import * as Yup from "yup";

const Registration = () => {
  let countries = [];
  const [data, setData] = useState([]);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailConfirmation, setEmailConfirmation] = useState(null);
  const [pwd, setPwd] = useState(null);
  const [pwdConfirmation, setPwdConfirmation] = useState(null);
  const [nationalities, setNationalities] = useState([]);

  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const device = deviceDetector.parse(userAgent);

  const actualDevice = device.device.type;

  //Ajout et affichage de la photo
  let uploaded_image = null;
  let uploaded_verification_image = null;
  let profile_picture = null;
  let verification_picture = null;

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/confirmation`;
    navigate(path);
  };

  //Comparaison des emails pour vérifier qu'ils sont identiques
  const emailListener = (e) => {
    setEmail(e.target.value);
  };
  //Comparaison des emails pour vérifier qu'ils sont identiques
  const emailConfirmationListener = (e) => {
    setEmailConfirmation(e.target.value);
  };

  //Comparaison des emails pour vérifier qu'ils sont identiques
  const pwdListener = (e) => {
    setPwd(e.target.value);
  };
  //Comparaison des emails pour vérifier qu'ils sont identiques
  const pwdConfirmationListener = (e) => {
    setPwdConfirmation(e.target.value);
  };

  //Recuperation de la liste de pays pour la nationalité
  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((res) => setData(res.data));
  }, []);
  data.map((country) => countries.push(country.translations.fra.common));
  countries.sort();

  return (
    <div className="formContainer">
      <div className="registerTitle">
        <h2>Inscris-toi dès maintenant !</h2>
      </div>

      {/* Champ formulaire Prénom */}
      <div className="formBox">
        <input
          type="text"
          required="required"
          onChange={(val) => {
            setFirstName(val.target.value);
          }}
          id="firstNameInput"
        />
        <span>* Prénom </span>
      </div>

      {/* Champ formualaire Nom */}
      <div className="formBox">
        <input
          type="text"
          required="required"
          id="lastNameInput"
          onChange={(val) => {
            setLastName(val.target.value);
          }}
        />
        <span>* Nom </span>
      </div>

      {/* Champ formulaire Date de naissance */}
      <div className="formBox">
        <label htmlFor="birthday">
          <abbr title="(obligatoire)">*</abbr>Date de naissance :
        </label>
        <input
          type="date"
          id="birthday"
          name="birthday"
          required="required"
          onChange={(val) => {
            setBirthDate(val.target.value);
          }}
          className="birthSelect"
        />
      </div>

      {/* Champ formualaire Sexe */}
      <div className="formBox">
        <label htmlFor="sexe-select">
          <abbr title="(obligatoire)">*</abbr>Sexe :
        </label>
        <select name="sexe-select" id="sexe-select" required="required">
          <option value="" hidden>
            Vous êtes un(e)..
          </option>
          <option value="Homme">Homme</option>
          <option value="Femme">Femme</option>
          <option value="Autre">Autre</option>
        </select>
      </div>

      {/* Champ formulaire Orientation sexuelle */}
      <div className="formBox">
        <label htmlFor="orientation-select">
          <abbr title="(obligatoire)">*</abbr>Je cherche :
        </label>
        <select
          name="orientation-select"
          id="orientation-select"
          required="required"
        >
          <option value="" hidden>
            Vous recherchez..
          </option>
          <option value="Homme">Un homme</option>
          <option value="Femme">Une femme</option>
          <option value="Both">Les deux</option>
        </select>
      </div>

      {/* Champ formulaire pour le type de relation recherché */}
      <div className="formBox">
        <label htmlFor="relation-type-select">
          <abbr title="(obligatoire)">*</abbr>Type de relation :
        </label>
        <select
          name="relation-type-select"
          id="relation_type-select"
          required="required"
        >
          <option value="" hidden>
            Vous recherchez..
          </option>
          <option value="Long terme">Long terme</option>
          <option value="Date rapide">Date rapide</option>
          <option value="Discussion">Discussion</option>
        </select>
      </div>

      {/* Champ pour la/les nationalité(s) */}
      <Formik
        initialValues={{
          nationalities: []
      }}
        validationSchema={Yup.object().shape({
          nationalities: Yup.array().min(1, "Vous devez choisir au moins 1 pays")
        })}
        onSubmit={(values) => {
          setNationalities(values.nationalities);
        }}
      >
        {(formikProps) => (
          <Form>
            <div
              className="formBox"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="nationalities">
                <abbr title="(obligatoire)">*</abbr>Nationalité(s) :
              </label>
              <Field as="select" name="nationalities">
                {countries.map((country) => (
                  <option value={country}>{country}</option>
                ))}
              </Field>
              <div style={{color: "red"}}>
                <ErrorMessage name="nationalities" />
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* Champ adresse mail */}
      <div className="formBox">
        <label htmlFor="email">
          <abbr title="(obligatoire)">*</abbr>Entrez une adresse mail :
        </label>
        <input
          type="email"
          id="email"
          name="email"
          required="required"
          onChange={emailListener}
        />
      </div>

      {/* Champ de vérification de l'adresse mail */}
      <div className="formBox">
        <label htmlFor="email-confirmation">
          <abbr title="(obligatoire)">*</abbr>Confirmez l'adresse mail :
        </label>
        <input
          type="email"
          id="email-confirmation"
          name="email-confirmation"
          required="required"
          onChange={emailConfirmationListener}
          className={email === emailConfirmation ? "" : "invalidConfirmation"}
        />
      </div>

      {/* Champ Mot de passe */}
      <div className="formBox">
        <label htmlFor="pwd">
          <abbr title="(obligatoire)">*</abbr>Mot de passe :
        </label>
        <input
          type="password"
          id="pwd"
          name="pwd"
          required="required"
          onChange={pwdListener}
        />
      </div>

      {/* Champ de vérification du mot de passe */}
      <div className="formBox">
        <label htmlFor="pwd-confirmation">
          <abbr title="(obligatoire)">*</abbr>Confirmez le mot de passe :
        </label>
        <input
          type="password"
          id="pwd-confirmation"
          name="pwd-confirmation"
          required="required"
          onChange={pwdConfirmationListener}
          className={pwd === pwdConfirmation ? "" : "invalidConfirmation"}
        />
      </div>

      {/* Champ de sélection de la zone de recherche */}
      <div className="formBox">
        <label htmlFor="zone-select">
          <abbr title="(obligatoire)">*</abbr>Zone :
        </label>
        <select name="zone-select" id="zone-select" required="required">
          <option value="Paris">Paris</option>
          <option value="Bordeaux">Bordeaux</option>
          <option value="Madrid">Madrid</option>
        </select>
      </div>

      {/* Champ d'ajout de photo
      <div className="formBox">
        <label htmlFor="imageFile">Ajouter une photo:</label>
        <input
          type="file"
          id="imageFile"
          multiple="multiple"
          accept="image/*"
          onChange={() => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              uploaded_image = reader.result;
              document.querySelector("#display_image_box").style.backgroundImage = `url(${uploaded_image})`;
            });
            reader.readAsDataURL(document.querySelector("#imageFile").files[0]);

            const readerText = new FileReader();
            readerText.addEventListener("load", () => {
              profile_picture = readerText.result;
              document.querySelector("#display_image_box").style.backgroundImage = `url(${uploaded_image})`;
            });
            readerText.readAsText(document.querySelector("#imageFile").files[0]);
          }}
        />
      </div>
      <div className="formBox display_image" id="display_image_box"></div>
      <div
        className={
          actualDevice === "desktop" ? "formBox hiddenImageInput" : "formBox"
        }
      >
         Photo pour la vérification d'identité

        <label htmlFor="imageFileVerification">Ajouter un selfie:</label>
        <p>
          (Cette photo ne sera visible nulle part, elle sera uniquement utilisée
          pour vérifier votre identité)
        </p>
        <input
          type="file"
          id="imageFileVerification"
          capture="camera"
          accept="image/*"
          onChange={() => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              uploaded_verification_image = reader.result;
              document.querySelector("#display_verification_image_box").style.backgroundImage =
                `url(${uploaded_verification_image})`;
            });
            reader.readAsDataURL(document.querySelector("#imageFileVerification").files[0]);

            const readerText = new FileReader();
            readerText.addEventListener("load", () => {
                verification_picture = readerText.result;
                document.querySelector("#display_verification_image_box").style.backgroundImage =
                    `url(${uploaded_verification_image})`;
            });
            readerText.readAsText(document.querySelector("#imageFileVerification").files[0]);
          }}
        />
      </div>
      <div
        className={
          actualDevice === "desktop"
            ? "formBox display_verification_image hiddenImageInput"
            : "formBox display_verification_image"
        }
        id="display_verification_image_box"
      ></div>
      <div className="formBox">
        <p>
          Les champs avec
          <abbr title="(obligatoire)"> * </abbr>
          sont obligatoires.
        </p>
      </div>*/}

      {/* Bouton permettant d'envoyer toutes les données du formulaire */}
      <button
        id="gooey-button"
        onClick={() => {
          if (
            email === emailConfirmation &&
            pwd === pwdConfirmation &&
            firstName != null &&
            lastName != null &&
            birthDate != null &&
            document.getElementById("sexe-select").value != null &&
            document.getElementById("orientation-select").value != null &&
            document.getElementById("relation_type-select").value != null &&
            nationalities !== [] &&
            document.getElementById("zone-select").value != null
          ) {
            Register({
              first_name: firstName,
              last_name: lastName,
              birthdate: new Date(birthDate),
              gender: document.getElementById("sexe-select").value,
              sexual_orientation:
              document.getElementById("orientation-select").value,
              relation_type: document.getElementById("relation_type-select").value,
              city: document.getElementById("zone-select").value,
              nationalities: nationalities,
              email: email,
              password: pwd,
            })
              .then(function (res) {
                if (profile_picture) {
                  const contentType = uploaded_image.split(";")[0].split(":")[1];
                  AddProfilePicture(profile_picture, contentType, res.data.id).
                  then().
                  catch();
                }
                if (verification_picture) {
                  const contentType = uploaded_verification_image.split(";")[0].split(":")[1];
                  AddVerificationPicture(verification_picture, contentType, res.data.id).
                  then().
                  catch();
                }
              })
              .catch(function (erreur) {
                console.log(erreur);
              });

            routeChange();
          } else {
            alert("Vérifiez que tous les champs sont correctement remplis");
          }
        }}
      >
        <span id="confirmation-btn">Confirmer l'inscription</span>
        <span className="bubbles">
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
          <span className="bubble"></span>
        </span>
      </button>
    </div>
  );
};

export default Registration;
